<template>
  <!-- <Card>
    <template v-slot:header>
      <span>{{ header }}&nbsp;&nbsp;</span>
      <img src="@/assets/images/error_red_icon.png" alt />
    </template>
    <div class="text">{{ content }}</div>
    <el-button class="btn-red" @click="confirm" data-testid="confirm">
      {{ btnText }}
    </el-button>
  </Card> -->
  <div>
    <StatusAlert :title="header" type="warning" show-icon :closable="false">
      <div class="alert_description">
        <p>{{ content }}</p>
        <a type="text" @click="confirm" data-testid="confirm" class="description_btn">{{ btnText }}</a>
      </div>
    </StatusAlert>
  </div>
</template>

<script>
import Card from '@/components/Card'
import StatusAlert from '@/components/customUI/StatusAlert.vue'

export default {
  name: 'pendingAction',
  components: { Card, StatusAlert },
  props: {
    type: String,
  },
  computed: {
    header() {
      return this.$t('identityProof.home.header')
    },
    content() {
      return this.$t('identityProof.home.content', {
        name: this.$store.state.common.individualUserName,
      })
    },
    btnText: function() {
      switch (this.type) {
        case 'questionnaire':
          return this.$t('common.button.completeQuestionnaire')
        case 'identityProof':
          return this.$t('common.button.identityProof')
      }
    },
  },
  methods: {
    confirm() {
      this.$emit('clickedPendingAction', this.type)
    },
  },
}
</script>

<style lang="scss" scoped>
// @import '@/assets/css/components/home/pendingAction.scss';
.alert_description {
  display: flex;
  align-items: center;
  .description_btn {
    color: #007aff;
    text-decoration: underline;
  }
}
</style>
